<template>
  <a
    href="#"
    class="flix-flex flix-flex-center flix-form-group flix-toggle-switch"
    style="justify-content: flex-start; flex-wrap: nowrap"
    @click.prevent="setClick"
  >
    <span class="check-icon">
      <icon id="check" class="icon" v-if="select" />
      <icon id="menu-close" class="icon no" v-else />
    </span>
    <label class="flix-label flix-html-label"
      >{{ title }} <help class="flix-help" v-if="help">{{ help }}</help></label
    >
  </a>
</template>
<script>
export default {
  components: {},
  props: {
    title: String,
    value: Boolean,
    callback: Function,
    help: String
  },
  data() {
    return {
      select: this.value
    };
  },
  computed: {},
  mounted() {},
  methods: {
    setClick() {
      this.select = !this.select;
      this.callback(this.select);
    }
  }
};
</script>
<style lang="sass" scoped></style>
