import { render, staticRenderFns } from "./toggleSwitch.vue?vue&type=template&id=44bd6681&scoped=true&"
import script from "./toggleSwitch.vue?vue&type=script&lang=js&"
export * from "./toggleSwitch.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44bd6681",
  null
  
)

export default component.exports